<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-row>
            <v-col cols="3"
                   v-for="(item, index) in modules"
                   v-bind:key="index"
                   class="viewCard">
                    <router-link :to="item.link">
                        <HoverCard :cardData="item"></HoverCard>
                    </router-link>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "Dashboard",
        watch: {},
        props: [
            'moduleName'
        ],
        components: {
            HoverCard
        },
        computed: {
            modules() {
                return [
                    {
                        name: this.$t('arrival_report'),
                        type: "monthlyYearlyReport",
                        link: "/reportModules/reportTourist-Arrival/report-arrival",
                        icon: "mdi-chart-areaspline"
                    },
                    {
                        name: this.$t('national_international_report'),
                        type: "monthlyYearlyReport",
                        link: "/reportModules/reportTourist-Arrival/national-international",
                        icon: "mdi-chart-bar"
                    },
                    {
                        name: this.$t('international_passenger_movement_report'),
                        type: "InternationalPassengerMovementReport",
                        link: "/reportModules/reportTourist-Arrival/InternationalPassengerMovementReport",
                        icon: "mdi-chart-sankey"
                    },
                    //{
                    //    name: this.$t('average_length_of_stay_report'),
                    //    type: "AverageLengthOfStay",
                    //    link: "/reportModules/reportTourist-Arrival/AverageLengthOfStay",
                    //    icon: "mdi-chart-timeline"
                    //},
                    {
                        name: this.$t('information_report'),
                        type: "PassengerInfoReport",
                        link: "/reportModules/reportTourist-Arrival/PassengerInfoReport",
                        icon: "mdi-chart-pie"
                    },
                ]
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('tourist_arrival'),
                        disabled: true,
                        exact: true
                    }
                    ]
            }
        },
        //data() {
        //    return {
        //        items: [
        //            {
        //                text: this.$t('reports'),
        //                exact: true,
        //                to: '/reportModules'
        //            },
        //            {
        //                text: this.$t('tourist_arrival'),
        //                disabled: true,
        //                exact: true
        //            }],
        //        }
       
        //},
        mounted() {
        },
        methods: {

        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>